// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import '@hotwired/turbo-rails'
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
import '~/application.css'

// import '../controllers'

import 'flowbite'

// Font Awesome
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

// Vue
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import router from '%/router.js'
import App from '&/App.vue'
import Notifications from '@kyvg/vue3-notification'
import AppBreadcrumbs from '&/AppBreadcrumbs.vue'
import VueSafeHTML from 'vue-safe-html'
import AppAdSense from '&/adsense/AppAdSense.vue'

document.addEventListener('turbo:load', () => {
  const app = createApp(App)
  const pinia = createPinia()
  const head = createHead()

  app.use(pinia)
  app.use(head)
  app.use(router)
  app.use(Notifications)
  app.use(VueSafeHTML)
  app.component('AppBreadcrumbs', AppBreadcrumbs)
  app.component('AppAdSense', AppAdSense)
  app.mount('#app')
})
